import React from "react";

export default function BadLarry() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1125.000000 1096.000000"
      preserveAspectRatio="xMidYMid meet"
      className="fill-current"
    >
      <g
        transform="translate(0.000000,1096.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M5470 9848 c-123 -11 -284 -48 -387 -87 -159 -62 -324 -193 -373
  -297 -13 -27 -24 -50 -25 -52 -1 -1 -15 3 -32 8 -23 9 -45 7 -100 -8 -91 -26
  -142 -52 -233 -121 -41 -31 -101 -73 -132 -95 -70 -47 -127 -109 -269 -296
  -35 -47 -76 -112 -90 -145 -40 -90 -89 -278 -100 -376 -6 -60 -19 -110 -42
  -166 -33 -77 -115 -219 -158 -275 -12 -14 -19 -30 -15 -36 3 -5 37 -12 76 -16
  66 -7 70 -9 70 -32 0 -24 -28 -83 -71 -151 -14 -22 -30 -96 -55 -245 -29 -174
  -36 -244 -38 -383 -3 -191 -26 -419 -59 -575 -25 -120 -26 -273 -5 -560 32
  -415 -4 -720 -106 -920 -20 -38 -36 -87 -39 -119 -4 -30 -11 -59 -17 -65 -13
  -13 -13 -59 1 -73 9 -9 -1 -32 -36 -91 -26 -44 -58 -90 -71 -103 -13 -12 -24
  -30 -24 -38 0 -25 -88 -68 -161 -79 -98 -15 -152 -48 -295 -179 -185 -169
  -247 -236 -311 -338 -32 -49 -104 -157 -161 -240 -57 -82 -127 -195 -154 -250
  -28 -55 -56 -107 -63 -115 -20 -23 -226 -427 -295 -580 -150 -329 -210 -507
  -330 -985 -48 -192 -104 -400 -124 -461 -43 -133 -44 -144 -11 -144 22 0 27
  10 64 125 22 68 81 286 131 484 94 375 162 590 251 801 54 129 295 621 308
  630 4 3 38 70 76 150 61 126 122 227 281 460 21 30 56 82 78 115 63 95 151
  190 289 310 174 153 188 162 277 176 41 6 92 16 112 21 125 36 337 -152 543
  -482 89 -142 118 -212 176 -416 81 -290 89 -376 59 -659 -24 -238 -51 -396
  -105 -625 -9 -36 -24 -105 -35 -155 -10 -49 -43 -185 -74 -300 -30 -116 -66
  -273 -80 -350 -14 -77 -30 -151 -36 -165 -14 -35 -28 -139 -23 -161 3 -11 11
  -19 18 -19 22 0 36 27 44 82 27 180 55 304 172 763 39 153 48 191 69 295 12
  58 30 146 41 195 10 50 19 107 19 127 0 20 5 49 10 64 6 15 15 89 20 163 6 75
  15 171 21 213 16 113 -2 304 -41 438 -120 402 -187 536 -398 789 -99 117 -186
  195 -259 232 -24 11 -43 24 -43 28 0 18 38 39 90 51 30 7 73 19 95 27 22 8 96
  27 165 43 183 43 296 75 373 104 37 14 72 26 77 26 17 0 11 -43 -10 -75 -39
  -58 -12 -88 41 -46 l24 19 240 -236 c432 -424 621 -592 846 -753 362 -259 822
  -462 1131 -500 147 -18 282 4 495 79 189 67 242 108 347 270 127 196 224 403
  292 619 20 65 37 119 38 120 1 1 19 -2 41 -7 22 -5 123 -21 225 -35 102 -14
  192 -28 200 -30 13 -4 15 -50 16 -327 0 -235 5 -365 17 -478 27 -245 326
  -2562 335 -2595 9 -35 45 -43 50 -10 1 11 -65 544 -148 1185 -167 1289 -184
  1434 -201 1695 -10 158 -7 501 5 512 7 7 124 -28 198 -58 98 -40 196 -107 294
  -201 302 -289 528 -682 604 -1048 33 -162 46 -290 120 -1201 51 -625 76 -888
  85 -898 18 -18 45 -7 45 19 0 61 -153 1842 -165 1930 -21 143 -70 336 -112
  445 -139 354 -448 764 -701 926 -146 93 -281 134 -597 180 -115 16 -246 38
  -290 49 -180 42 -310 106 -414 205 l-66 64 66 68 c37 37 75 84 84 103 10 19
  31 54 47 78 16 23 40 73 52 110 30 86 54 133 77 150 11 7 19 21 19 30 0 10 11
  33 23 52 13 20 50 126 82 240 32 112 76 241 97 286 32 67 43 105 58 210 11 71
  20 163 20 205 0 59 4 82 19 100 29 37 47 78 66 152 15 54 23 69 41 73 28 7 44
  -2 44 -25 0 -23 32 -52 46 -43 6 4 14 25 17 47 4 22 27 103 53 180 43 130 47
  148 48 247 l0 107 26 -24 c15 -14 39 -52 55 -85 30 -62 55 -77 73 -42 5 9 12
  91 15 182 4 134 10 184 32 265 98 376 108 563 39 754 -10 28 -15 53 -12 56 8
  9 112 -33 154 -61 40 -28 54 -22 54 25 0 46 -79 264 -120 328 -8 12 -11 25 -8
  29 10 9 208 -32 268 -56 96 -38 101 -25 32 81 -59 90 -111 138 -194 180 -96
  48 -168 101 -268 194 -50 47 -126 112 -168 145 -42 33 -118 106 -169 163 -80
  91 -100 108 -157 133 -72 31 -82 44 -37 44 17 0 33 5 37 11 4 6 -40 58 -102
  121 -112 112 -141 131 -259 167 -35 11 -94 35 -133 55 -102 51 -173 73 -302
  90 -63 9 -144 24 -180 35 -36 11 -103 25 -150 31 -47 7 -161 27 -255 46 -319
  63 -500 75 -745 52z m-102 -1302 c262 -124 481 -192 593 -184 40 3 44 6 47 31
  4 36 4 36 197 -7 191 -42 673 -87 943 -86 98 0 125 -4 190 -27 68 -23 85 -35
  149 -100 64 -65 73 -79 84 -130 14 -69 22 -167 22 -280 1 -75 3 -82 21 -85 18
  -3 21 -11 24 -56 4 -65 -28 -130 -68 -138 -45 -10 -51 -14 -44 -25 3 -6 22 -9
  40 -7 19 2 34 0 34 -4 0 -4 -4 -8 -9 -8 -5 0 -9 -79 -9 -175 -1 -202 -7 -245
  -31 -245 -11 0 -28 -21 -46 -60 -20 -42 -47 -76 -87 -111 -60 -53 -70 -69 -40
  -69 29 0 117 85 148 144 15 30 32 52 36 50 13 -8 9 -52 -7 -91 -12 -28 -13
  -39 -3 -50 8 -10 13 -92 16 -267 5 -303 0 -334 -76 -418 -57 -62 -82 -118 -53
  -118 10 0 40 16 67 35 l49 35 3 -76 c2 -43 -2 -91 -8 -107 -5 -16 -10 -39 -10
  -50 0 -11 -8 -26 -17 -33 -17 -13 -17 -15 0 -28 18 -14 23 -36 8 -36 -15 0
  -89 -183 -112 -275 -11 -49 -27 -97 -35 -106 -8 -8 -14 -27 -14 -41 0 -36 -30
  -119 -41 -112 -5 3 -9 20 -9 38 0 18 -14 85 -31 147 -43 157 -43 159 -30 159
  21 0 11 29 -11 32 -19 3 -28 23 -65 143 -127 407 -178 542 -216 574 -23 19
  -128 9 -165 -15 l-27 -18 35 -8 c42 -11 47 -11 82 -2 25 6 30 2 48 -37 11 -26
  20 -66 20 -97 0 -52 -1 -53 -47 -82 -58 -37 -79 -37 -154 -2 -50 23 -59 30
  -54 47 16 52 17 73 6 154 -20 137 -41 239 -72 341 -37 122 -83 347 -99 475 -7
  55 -17 106 -22 113 -8 9 -4 12 13 12 43 0 54 14 39 50 -16 40 -50 62 -87 58
  -38 -4 -36 -30 5 -40 61 -16 33 -38 -49 -38 -40 0 -55 -5 -76 -26 -32 -33 -20
  -46 32 -34 77 18 82 16 95 -42 6 -29 15 -89 21 -133 5 -44 13 -91 18 -105 5
  -14 14 -56 21 -95 7 -38 34 -155 60 -260 27 -104 54 -229 61 -277 12 -78 11
  -90 -5 -127 -29 -63 -75 -105 -141 -130 -84 -32 -139 -25 -213 25 -91 63 -100
  82 -94 207 5 116 -3 180 -21 174 -8 -2 -12 -46 -14 -137 -3 -156 -9 -170 -88
  -206 -44 -21 -60 -23 -93 -16 -110 25 -139 107 -65 184 27 28 26 58 -3 58 -23
  0 -278 -257 -302 -305 -59 -119 -91 -219 -112 -350 -13 -81 -64 -261 -91 -323
  -25 -56 -25 -82 1 -82 24 0 43 35 64 116 8 32 24 87 35 121 11 35 25 91 31
  125 14 82 19 98 31 98 17 0 59 -79 59 -111 0 -29 -2 -31 -26 -25 -22 6 -25 4
  -20 -9 3 -9 -3 -23 -14 -32 -11 -10 -20 -25 -20 -35 0 -10 -5 -18 -10 -18 -6
  0 2 -16 17 -34 20 -25 31 -32 39 -25 7 6 21 9 30 7 11 -2 20 4 22 15 3 12 -3
  17 -22 17 -28 0 -41 14 -32 35 7 19 62 0 77 -26 11 -21 49 -26 49 -7 0 19 32
  48 52 48 10 0 33 7 49 16 24 12 30 20 25 37 -4 17 1 25 20 34 49 23 69 -5 38
  -52 -11 -16 -13 -29 -6 -40 8 -15 12 -13 35 14 14 17 30 31 36 31 21 0 22 23
  1 45 l-22 23 21 12 c22 11 21 12 35 -34 6 -19 65 -31 79 -17 28 28 85 -3 102
  -56 6 -22 34 -31 48 -17 3 4 0 22 -8 41 -18 44 -19 40 5 46 11 3 20 12 20 20
  0 7 -9 22 -20 32 -11 10 -20 13 -20 7 0 -7 -6 -12 -14 -12 -19 0 -33 23 -18
  28 9 3 7 12 -5 33 -20 34 -11 49 16 25 11 -10 25 -15 31 -11 6 3 24 3 41 0 23
  -5 28 -10 20 -18 -17 -17 -13 -67 9 -94 11 -14 20 -31 20 -38 0 -22 44 -55 60
  -45 12 8 13 13 3 28 -6 11 -15 36 -18 56 -6 32 -5 37 19 46 14 5 26 14 26 20
  0 5 7 10 15 10 17 0 19 -9 6 -29 -6 -9 -4 -29 4 -52 9 -23 10 -41 4 -44 -5 -4
  -9 2 -9 11 0 12 -3 15 -10 8 -7 -7 -7 -21 1 -42 12 -34 23 -40 36 -19 4 8 16
  16 26 20 9 3 17 13 17 22 0 10 10 4 29 -17 28 -31 30 -32 48 -15 17 15 22 15
  39 2 25 -19 30 -19 53 6 16 17 23 19 40 10 15 -9 26 -9 42 -1 16 9 24 8 38 -4
  23 -20 120 -46 127 -34 3 5 -6 21 -21 36 -69 72 -107 123 -101 137 3 8 1 26
  -4 40 -6 17 -6 25 1 25 5 0 15 -13 20 -30 9 -25 16 -30 45 -30 24 0 34 -5 34
  -15 0 -8 -7 -15 -15 -15 -9 0 -15 -9 -15 -25 0 -30 33 -35 50 -7 9 14 10 10 6
  -20 -4 -34 -2 -39 30 -59 27 -16 45 -20 75 -16 39 5 42 3 72 -39 17 -24 39
  -44 49 -44 10 0 18 -7 18 -15 0 -8 2 -15 4 -15 2 0 11 -3 20 -6 23 -9 21 32
  -4 63 -11 14 -20 40 -20 57 0 17 -12 51 -27 76 -17 29 -25 58 -24 82 1 20 -3
  39 -9 43 -6 4 -15 18 -20 30 -12 31 -52 57 -74 49 -10 -4 -22 -16 -26 -28 l-9
  -21 0 21 c-1 28 -10 35 -32 23 -23 -12 -25 3 -4 21 19 16 20 50 1 50 -7 0 -19
  -6 -25 -14 -7 -8 -21 -12 -31 -9 -14 4 -20 0 -20 -12 0 -9 -3 -15 -7 -13 -5 2
  -30 14 -56 27 -53 26 -65 24 -47 -9 21 -40 2 -33 -24 9 -28 43 -40 48 -68 27
  -27 -20 -22 -63 10 -92 27 -25 27 -25 2 -13 -45 21 -60 40 -53 67 6 24 5 25
  -38 20 -24 -3 -51 -9 -60 -12 -14 -6 -16 1 -15 55 l2 62 74 43 c41 24 85 43
  98 43 13 1 44 -10 70 -24 26 -14 63 -25 85 -25 50 0 115 34 135 71 39 70 36
  74 91 -102 29 -90 74 -231 101 -314 83 -252 90 -278 104 -389 l14 -108 -32
  -61 c-18 -34 -43 -93 -55 -132 -13 -38 -47 -108 -76 -155 -54 -86 -148 -190
  -172 -190 -7 0 -13 -6 -13 -14 0 -14 -138 -139 -245 -222 -68 -53 -142 -70
  -287 -66 -135 3 -323 34 -348 57 -11 10 -26 15 -36 11 -10 -4 -21 -1 -25 5 -4
  6 -31 15 -60 20 -55 9 -258 103 -357 166 -66 41 -142 111 -142 129 0 8 -6 14
  -12 15 -17 1 -168 166 -168 183 0 8 -26 42 -57 76 -76 82 -356 360 -363 360
  -3 0 -16 14 -30 30 -14 17 -30 30 -36 30 -6 0 -24 17 -40 38 -16 20 -62 71
  -104 112 -69 69 -148 189 -134 203 3 3 -2 17 -11 30 -9 14 -19 51 -22 82 -5
  51 -4 57 15 62 23 5 39 36 23 41 -6 2 -8 22 -6 45 2 23 7 105 9 182 3 78 8
  148 11 157 11 28 -13 57 -83 102 -73 47 -124 104 -128 145 -2 14 -7 58 -13 96
  -18 122 -14 281 10 365 24 88 55 147 81 156 29 9 93 -39 124 -91 17 -31 29
  -69 34 -114 4 -37 12 -76 18 -87 22 -42 26 -122 7 -170 -24 -63 -12 -73 58
  -51 65 21 67 21 67 2 0 -8 4 -15 8 -15 15 0 31 22 25 36 -7 20 56 49 78 36 14
  -9 21 -6 34 15 16 23 16 34 5 97 -6 39 -25 125 -42 191 -56 224 -66 333 -48
  499 11 107 60 278 93 330 8 12 17 33 20 47 4 14 41 63 83 108 74 81 144 200
  144 244 0 68 73 347 91 347 6 0 90 -38 187 -84z m478 -2395 c-4 -5 3 -30 13
  -54 16 -38 28 -49 68 -67 82 -37 107 -37 184 0 77 38 86 36 150 -23 40 -36 77
  -52 142 -61 58 -9 74 -33 60 -93 -8 -36 -11 -38 -48 -38 -29 0 -41 4 -43 17
  -2 12 2 15 17 11 15 -4 21 -1 21 11 0 12 -9 16 -34 16 -41 0 -46 -6 -54 -68
  l-7 -46 -43 39 c-42 37 -43 39 -22 47 26 10 21 34 -6 30 -65 -11 -81 -16 -97
  -30 -13 -12 -17 -25 -13 -43 6 -31 -9 -42 -61 -44 -36 -2 -41 9 -18 41 17 26
  11 34 -27 34 -24 0 -28 -4 -28 -29 0 -31 -14 -49 -25 -31 -4 6 -15 8 -25 5
  -10 -3 -21 -1 -25 5 -3 6 -11 8 -16 4 -6 -3 1 -20 15 -38 18 -25 22 -36 14
  -44 -9 -9 -7 -17 7 -32 10 -11 15 -23 11 -27 -4 -4 -19 8 -34 27 -48 62 -62
  73 -77 60 -10 -9 -21 -8 -42 3 l-28 15 28 13 c31 14 35 34 10 47 -13 8 -24 4
  -44 -15 -28 -27 -28 -48 -2 -89 7 -11 11 -23 7 -26 -10 -11 -44 4 -44 20 0 13
  -2 13 -18 -1 -23 -21 -75 -22 -92 -2 -17 20 -14 22 45 32 36 6 51 13 53 26 3
  16 -2 18 -35 16 -21 -1 -50 -2 -65 -1 -24 2 -26 4 -18 30 18 59 79 193 100
  219 50 63 171 175 146 134z m-971 -925 c49 -49 110 -104 135 -122 47 -35 175
  -181 219 -249 14 -23 46 -61 71 -86 25 -25 80 -84 123 -132 43 -48 84 -87 91
  -87 6 0 36 -16 66 -36 30 -20 75 -46 100 -58 25 -12 65 -34 90 -48 25 -15 73
  -34 108 -44 34 -10 62 -21 62 -25 0 -27 312 -71 515 -73 171 -1 195 4 240 54
  11 12 40 34 65 48 25 14 77 53 115 88 l70 61 73 -68 73 -68 -12 -43 c-64 -239
  -363 -507 -674 -604 -79 -25 -84 -25 -129 -10 -50 17 -92 9 -102 -19 -5 -12
  -25 -15 -98 -14 -103 0 -121 6 -121 34 0 13 -8 21 -22 23 -21 3 -28 -6 -24
  -30 2 -13 -21 -9 -130 21 -188 54 -361 132 -488 221 -87 62 -248 222 -354 352
  -146 181 -268 385 -332 558 -15 41 -32 80 -36 86 -5 6 7 35 28 70 49 81 82
  174 89 247 10 116 8 113 57 75 23 -18 82 -73 132 -122z m-256 -530 c132 -267
  412 -614 626 -774 181 -136 541 -268 783 -288 81 -7 83 -8 78 -30 -3 -12 -1
  -46 5 -75 14 -62 72 -132 126 -150 87 -28 185 18 212 100 11 36 11 48 -4 94
  -9 29 -26 62 -37 73 l-20 22 54 17 c309 102 604 367 683 613 10 28 19 52 21
  52 3 0 30 -13 62 -29 31 -15 82 -36 112 -46 30 -10 58 -19 62 -21 19 -11 -104
  -340 -188 -504 -73 -144 -203 -339 -248 -374 -53 -40 -169 -92 -174 -78 -6 20
  -43 14 -49 -8 -4 -15 -26 -26 -84 -45 -198 -62 -367 -60 -593 9 -73 23 -86 29
  -76 41 7 8 10 22 6 31 -8 22 -51 15 -50 -9 2 -20 -2 -21 -45 -6 -20 7 -31 18
  -31 30 0 25 -26 35 -45 19 -13 -11 -40 -1 -163 59 -175 86 -318 170 -369 214
  -20 18 -38 30 -41 28 -7 -6 -178 119 -283 208 -118 98 -321 288 -619 579
  l-225 219 25 22 c14 12 42 30 62 40 21 10 65 47 98 83 43 47 68 67 90 70 41 6
  45 -4 25 -57 -21 -52 -15 -75 19 -75 28 0 55 33 88 107 l21 48 18 -54 c10 -29
  41 -99 68 -155z"
        />
        <path
          d="M5857 8183 c-21 -21 -2 -31 71 -36 42 -4 102 -11 132 -17 39 -7 84
  -7 155 2 55 6 187 14 294 17 142 4 195 9 199 19 2 8 -8 12 -30 13 -55 2 -299
  -3 -327 -7 -14 -1 -82 -6 -151 -10 -100 -5 -138 -3 -188 10 -65 16 -143 21
  -155 9z"
        />
        <path
          d="M6790 8050 c-8 -5 -40 -14 -70 -20 -30 -6 -100 -22 -155 -35 -97 -23
  -457 -55 -677 -60 -114 -3 -108 -2 -108 -26 0 -23 -24 -24 368 7 101 8 205 14
  232 14 27 0 51 4 54 8 3 5 18 7 33 5 15 -2 69 6 118 18 50 11 106 23 125 26
  19 3 41 10 49 17 9 7 64 11 153 11 87 0 137 4 134 10 -1 6 -14 10 -27 9 -13
  -1 -29 5 -37 12 -16 16 -168 19 -192 4z"
        />
        <path
          d="M5938 7783 c-3 -10 -22 -24 -42 -31 -34 -13 -40 -12 -59 6 -35 31
  -44 33 -63 15 -10 -11 -28 -16 -46 -14 -25 2 -28 -1 -28 -24 0 -27 0 -27 -26
  -10 -28 18 -54 14 -54 -10 0 -19 -38 -19 -64 0 -34 26 -56 19 -56 -16 0 -30
  -1 -31 -33 -25 -25 5 -40 1 -58 -13 -13 -10 -42 -24 -64 -30 -22 -6 -48 -21
  -58 -33 -13 -16 -20 -19 -24 -10 -8 21 -21 13 -52 -34 -31 -45 -36 -69 -25
  -112 6 -22 10 -23 79 -19 46 3 89 12 116 25 24 12 60 23 80 25 20 2 41 7 48
  11 7 5 36 11 64 16 29 4 102 18 162 30 85 17 130 20 195 16 120 -8 184 -17
  194 -27 10 -9 84 45 93 68 8 19 -44 83 -68 83 -32 0 -55 16 -78 55 -12 20 -28
  35 -39 35 -10 0 -27 9 -37 20 -22 25 -51 26 -57 3z"
        />
        <path
          d="M7153 7784 c-3 -7 -3 -21 1 -29 7 -18 -3 -19 -27 -1 -13 10 -43 13
  -108 11 -50 -2 -104 1 -120 5 -25 8 -33 5 -52 -16 -12 -14 -40 -33 -62 -43
  -22 -10 -46 -25 -54 -34 -17 -21 -45 -22 -61 -2 -18 22 -39 18 -46 -8 -3 -14
  -20 -31 -40 -40 -19 -9 -34 -20 -34 -25 0 -4 9 -26 20 -47 14 -28 28 -40 43
  -41 12 -1 39 -2 60 -3 30 -1 40 -6 44 -21 3 -11 14 -20 24 -20 20 0 44 35 34
  50 -4 6 6 10 23 10 17 0 37 9 48 21 14 16 25 19 49 14 47 -10 125 -6 186 11
  60 17 152 16 229 -3 25 -6 77 -19 115 -29 39 -10 88 -18 110 -18 38 -1 40 1
  53 38 11 33 10 42 -3 63 -9 13 -27 28 -40 33 -18 7 -52 45 -60 67 0 1 -29 -2
  -64 -6 l-65 -8 -43 40 c-39 36 -50 41 -99 43 -41 2 -56 -1 -61 -12z"
        />
        <path
          d="M6414 7629 c-10 -16 22 -43 42 -35 16 6 16 8 -1 26 -20 23 -31 25
  -41 9z"
        />
        <path d="M6310 7601 c0 -12 6 -21 16 -21 9 0 14 7 12 17 -5 25 -28 28 -28 4z" />
        <path
          d="M6350 7516 c0 -8 6 -16 13 -19 19 -7 41 8 33 22 -10 16 -46 14 -46
  -3z"
        />
        <path
          d="M6455 7480 c-3 -5 6 -12 20 -16 29 -7 41 -1 30 15 -8 14 -42 14 -50
  1z"
        />
        <path
          d="M6959 7424 c-7 -9 -29 -23 -48 -33 -20 -9 -35 -23 -33 -30 2 -13 -77
  -94 -121 -124 -27 -18 -37 -47 -15 -47 18 0 118 -79 115 -91 -1 -6 29 -22 67
  -35 53 -18 73 -21 89 -13 16 9 17 13 6 20 -8 5 -24 9 -37 9 -35 0 -93 28 -99
  47 -2 10 -23 31 -44 46 -22 15 -39 33 -36 40 6 21 69 57 98 57 27 0 33 8 30
  35 -1 13 54 44 65 36 5 -3 9 -35 10 -71 1 -83 13 -104 69 -124 57 -19 107 -6
  138 36 21 28 27 80 13 111 -7 14 -3 17 20 17 22 0 38 -10 65 -42 25 -29 41
  -39 52 -35 48 18 -10 82 -107 120 -40 15 -78 20 -173 21 l-122 1 20 21 c10 11
  19 26 19 32 0 17 -25 15 -41 -4z"
        />
        <path
          d="M5967 7383 c-4 -3 -7 -14 -7 -24 0 -16 -5 -17 -32 -11 -18 4 -85 7
  -148 6 -101 0 -124 -3 -187 -28 -39 -15 -83 -38 -98 -52 -31 -29 -42 -26 -25
  6 6 12 9 29 5 38 -5 13 -8 14 -19 1 -26 -26 -88 -159 -82 -174 9 -25 43 -17
  63 14 24 39 127 107 199 131 31 11 59 18 61 16 2 -2 -12 -19 -32 -39 -39 -39
  -46 -78 -19 -116 15 -21 15 -23 -5 -38 -43 -30 -19 -36 130 -34 79 1 164 7
  189 12 60 13 120 69 75 69 -9 0 -28 -6 -43 -14 -35 -18 -207 -33 -177 -15 33
  19 45 41 45 82 0 39 -14 76 -34 89 -20 13 -1 16 56 8 32 -5 72 -16 90 -24 28
  -15 34 -15 43 -3 6 8 19 16 30 18 11 3 21 12 23 22 3 14 -4 17 -32 17 -24 0
  -36 5 -36 14 0 17 -24 38 -33 29z"
        />
        <path
          d="M7280 7376 c0 -21 15 -28 69 -33 95 -9 106 -9 106 7 0 18 -74 33 -92
  19 -6 -6 -12 -6 -15 -1 -1 5 -18 13 -35 17 -26 5 -33 3 -33 -9z"
        />
        <path
          d="M6056 7251 c-10 -15 13 -63 27 -58 17 5 21 35 7 52 -14 17 -26 19
  -34 6z"
        />
        <path
          d="M7365 7140 c-56 -23 -166 -50 -206 -50 -42 0 -69 -13 -69 -32 0 -4
  35 -8 78 -8 61 1 92 7 149 29 40 16 74 26 77 24 10 -10 -7 -69 -26 -90 -11
  -12 -47 -34 -79 -50 -32 -15 -57 -32 -56 -38 6 -19 58 -17 81 2 11 10 34 25
  50 33 49 25 100 160 61 160 -9 0 -18 7 -21 15 -7 17 -10 17 -39 5z"
        />
        <path
          d="M6680 7106 c0 -45 65 -135 199 -276 51 -53 68 -60 78 -33 4 11 -6 27
  -34 52 -55 50 -157 160 -152 165 2 3 31 -13 64 -35 66 -43 95 -49 95 -20 0 14
  -16 27 -52 44 -29 13 -81 50 -115 81 -33 31 -66 56 -72 56 -6 0 -11 -16 -11
  -34z"
        />
        <path
          d="M5375 7072 c-36 -7 -38 -19 -12 -74 20 -46 33 -60 81 -86 57 -33 106
  -42 106 -19 0 6 -25 23 -55 36 -60 26 -105 62 -105 84 0 12 12 10 58 -9 66
  -27 95 -30 111 -10 10 12 3 16 -40 25 -28 6 -63 19 -78 30 -38 26 -44 28 -66
  23z"
        />
        <path
          d="M6077 7073 c-4 -3 -7 -17 -7 -30 0 -14 -4 -32 -9 -41 -15 -28 -113
  -91 -176 -113 -32 -11 -76 -29 -97 -40 -40 -20 -51 -49 -20 -49 31 0 213 77
  238 101 44 41 36 12 -21 -80 -61 -99 -70 -139 -23 -102 61 49 169 289 153 341
  -6 20 -25 26 -38 13z"
        />
        <path
          d="M4390 7050 c-34 -34 -42 -69 -41 -177 0 -56 -4 -114 -9 -128 -17 -44
  15 -140 43 -129 8 3 13 55 15 172 4 242 17 275 73 177 17 -31 36 -54 43 -52
  21 7 16 52 -11 102 -24 42 -33 51 -60 53 -22 2 -38 -3 -53 -18z"
        />
        <path d="M4837 6646 c-7 -16 1 -26 20 -26 7 0 13 9 13 20 0 23 -25 27 -33 6z" />
        <path
          d="M4945 6650 c-3 -5 1 -20 9 -32 9 -12 16 -32 16 -43 0 -11 8 -32 18
  -46 9 -14 41 -61 69 -104 37 -54 51 -84 47 -99 -3 -13 9 -52 31 -98 66 -143
  91 -248 116 -476 10 -95 10 -104 -6 -116 -14 -10 -15 -15 -5 -21 9 -6 19 -1
  28 13 8 12 20 22 28 22 20 0 26 36 15 93 -14 68 -32 190 -37 247 -9 105 -18
  139 -55 215 -21 43 -39 88 -39 98 0 10 -8 31 -19 45 -10 15 -32 56 -49 92 -16
  36 -46 87 -66 115 -20 27 -36 55 -36 61 0 14 -33 44 -48 44 -6 0 -14 -4 -17
  -10z"
        />
        <path
          d="M4604 6629 c-9 -15 11 -33 30 -26 9 4 13 13 10 22 -7 17 -30 20 -40
  4z"
        />
        <path d="M4788 6568 c-3 -7 -3 -19 -1 -25 6 -17 43 2 43 22 0 18 -36 20 -42 3z" />
        <path d="M4683 6545 c-3 -9 -3 -18 0 -21 9 -9 27 6 27 22 0 19 -19 18 -27 -1z" />
        <path
          d="M4605 6459 c-4 -12 -2 -26 5 -33 17 -16 44 22 29 41 -16 19 -27 16
  -34 -8z"
        />
        <path
          d="M4583 6403 c-23 -9 -15 -35 10 -31 12 2 22 9 22 17 0 16 -14 22 -32
  14z"
        />
        <path d="M4823 6385 c-3 -9 -3 -19 1 -22 7 -7 36 16 36 28 0 15 -30 10 -37 -6z" />
        <path
          d="M4964 6359 c-8 -14 11 -33 25 -25 16 10 13 36 -3 36 -8 0 -18 -5 -22
  -11z"
        />
        <path
          d="M4690 6320 c-12 -8 -12 -10 2 -15 20 -8 41 2 33 16 -8 11 -16 11 -35
  -1z"
        />
        <path
          d="M4930 6245 c-10 -12 -9 -16 4 -21 22 -8 52 16 34 27 -18 12 -24 11
  -38 -6z"
        />
        <path
          d="M4636 6223 c-3 -3 -6 -17 -6 -29 0 -17 4 -22 15 -18 8 4 15 14 15 24
  0 20 -13 33 -24 23z"
        />
        <path d="M4770 6171 c0 -12 6 -21 16 -21 9 0 14 7 12 17 -5 25 -28 28 -28 4z" />
        <path
          d="M4964 6159 c-8 -14 11 -33 25 -25 16 10 13 36 -3 36 -8 0 -18 -5 -22
  -11z"
        />
        <path d="M4920 6126 c0 -9 7 -16 16 -16 17 0 14 22 -4 28 -7 2 -12 -3 -12 -12z" />
        <path
          d="M5095 6120 c-8 -13 3 -30 20 -30 14 0 27 24 18 34 -10 9 -31 7 -38
  -4z"
        />
        <path d="M4686 6094 c-4 -11 0 -15 16 -12 12 2 23 9 26 16 6 18 -35 15 -42 -4z" />
        <path
          d="M5290 6076 c0 -20 27 -28 34 -10 9 23 8 24 -14 24 -11 0 -20 -6 -20
  -14z"
        />
        <path
          d="M4640 6042 c0 -29 23 -53 39 -43 12 8 12 13 -3 35 -19 30 -36 34 -36
  8z"
        />
        <path d="M4534 6009 c-9 -15 12 -33 30 -26 9 4 16 13 16 22 0 17 -35 21 -46 4z" />
        <path
          d="M4823 6013 c-7 -2 -13 -11 -13 -20 0 -20 34 -12 38 10 3 17 -3 20
  -25 10z"
        />
        <path
          d="M5131 6001 c-14 -9 -12 -44 3 -45 5 -1 14 7 20 16 13 21 -3 41 -23
  29z"
        />
        <path
          d="M4916 5984 c-9 -23 -8 -24 13 -24 19 0 35 23 24 34 -12 11 -31 6 -37
  -10z"
        />
        <path
          d="M5363 5945 c-3 -9 -3 -19 1 -22 8 -9 37 18 30 28 -8 14 -24 11 -31
  -6z"
        />
        <path d="M7306 5953 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24 19z" />
        <path
          d="M5022 5928 c2 -13 8 -23 13 -23 6 0 11 10 13 23 2 15 -2 22 -13 22
  -11 0 -15 -7 -13 -22z"
        />
        <path
          d="M7475 5930 c-5 -16 -10 -18 -20 -10 -10 8 -15 8 -21 -1 -8 -13 34
  -51 51 -45 6 1 11 12 13 24 2 12 10 22 19 22 22 0 10 24 -14 28 -14 2 -23 -4
  -28 -18z"
        />
        <path
          d="M4670 5912 c0 -26 11 -34 27 -21 10 9 10 15 2 25 -16 19 -29 17 -29
  -4z"
        />
        <path
          d="M6597 5910 c-7 -27 13 -37 30 -15 10 15 10 20 0 27 -19 12 -24 10
  -30 -12z"
        />
        <path
          d="M4612 5888 c-15 -15 3 -49 23 -42 19 8 19 28 2 43 -10 8 -17 7 -25
  -1z"
        />
        <path
          d="M4790 5831 c0 -24 18 -37 32 -23 8 8 7 16 -2 27 -17 21 -30 19 -30
  -4z"
        />
        <path d="M5407 5844 c-9 -10 2 -24 19 -24 8 0 14 7 14 15 0 15 -21 21 -33 9z" />
        <path d="M7206 5831 c-3 -5 3 -11 14 -14 24 -6 36 8 14 17 -20 8 -22 8 -28 -3z" />
        <path
          d="M5330 5815 c0 -11 7 -15 23 -13 12 2 22 8 22 13 0 6 -10 11 -22 13
  -16 2 -23 -2 -23 -13z"
        />
        <path
          d="M4907 5784 c-9 -9 23 -54 39 -54 20 0 26 17 14 40 -10 19 -40 27 -53
  14z"
        />
        <path d="M4844 5766 c-8 -22 3 -33 18 -18 9 9 9 15 0 24 -9 9 -13 7 -18 -6z" />
        <path d="M5125 5771 c-3 -5 -1 -12 5 -16 12 -7 30 2 30 16 0 12 -27 12 -35 0z" />
        <path
          d="M7414 5739 c-3 -6 -2 -15 4 -21 13 -13 32 -2 32 18 0 16 -26 19 -36
  3z"
        />
        <path d="M4584 5725 c-9 -23 14 -45 26 -25 5 8 8 19 7 24 -6 17 -27 18 -33 1z" />
        <path d="M5441 5716 c-9 -11 -8 -15 5 -20 20 -8 34 1 34 20 0 18 -24 18 -39 0z" />
        <path
          d="M7314 5715 c-12 -31 23 -48 45 -21 10 12 8 17 -8 25 -26 14 -31 14
  -37 -4z"
        />
        <path
          d="M4990 5705 c0 -21 30 -31 40 -14 11 17 3 29 -21 29 -10 0 -19 -7 -19
  -15z"
        />
        <path
          d="M4780 5670 c0 -15 5 -20 18 -18 9 2 17 10 17 18 0 8 -8 16 -17 18
  -13 2 -18 -3 -18 -18z"
        />
        <path
          d="M5182 5678 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
  -12z"
        />
        <path d="M7365 5649 c-4 -6 -4 -13 -1 -16 8 -8 36 5 36 17 0 13 -27 13 -35 -1z" />
        <path
          d="M5377 5644 c-14 -14 -7 -35 11 -32 9 2 17 10 17 17 0 16 -18 25 -28
  15z"
        />
        <path
          d="M4652 5614 c4 -21 22 -23 26 -1 2 10 -3 17 -13 17 -10 0 -15 -6 -13
  -16z"
        />
        <path
          d="M5017 5606 c-9 -23 18 -39 34 -20 11 13 10 17 -2 25 -20 12 -26 11
  -32 -5z"
        />
        <path
          d="M5155 5540 c-3 -5 -2 -17 2 -25 8 -13 12 -13 28 -1 18 13 18 14 -3
  25 -13 7 -23 8 -27 1z"
        />
        <path
          d="M4803 5514 c-9 -23 11 -38 26 -20 13 16 7 36 -10 36 -5 0 -12 -7 -16
  -16z"
        />
        <path
          d="M7330 5517 c0 -21 12 -28 27 -16 19 16 16 29 -7 29 -11 0 -20 -6 -20
  -13z"
        />
        <path
          d="M5141 5466 c-9 -10 -9 -16 -1 -21 17 -10 37 12 24 25 -8 8 -15 6 -23
  -4z"
        />
        <path
          d="M5330 5470 c-11 -6 -12 -12 -4 -20 13 -13 46 -2 41 13 -5 15 -20 18
  -37 7z"
        />
        <path
          d="M7042 5453 c4 -21 36 -29 51 -14 9 9 8 14 -3 21 -25 16 -52 12 -48
  -7z"
        />
        <path d="M4947 5454 c-10 -11 3 -24 24 -24 10 0 19 7 19 15 0 15 -31 21 -43 9z" />
        <path
          d="M6080 5407 c-7 -8 -296 -49 -342 -48 -22 0 -47 -8 -60 -19 -21 -17
  -21 -20 -8 -34 14 -14 30 -14 160 -1 80 8 198 17 262 21 109 6 125 5 223 -21
  l106 -28 97 18 c181 35 496 18 596 -31 31 -15 45 -7 46 24 0 20 -83 52 -209
  80 -101 22 -142 26 -321 27 -113 1 -281 5 -375 9 -93 5 -172 6 -175 3z"
        />
        <path
          d="M5540 5386 c0 -8 5 -18 10 -21 6 -3 10 3 10 14 0 12 -4 21 -10 21 -5
  0 -10 -6 -10 -14z"
        />
        <path
          d="M5000 5350 c0 -13 5 -20 13 -17 6 2 12 10 12 17 0 7 -6 15 -12 18 -8
  2 -13 -5 -13 -18z"
        />
        <path d="M5327 5353 c-11 -10 -8 -43 3 -43 15 0 24 30 13 41 -5 5 -12 6 -16 2z" />
        <path
          d="M5225 5309 c-4 -11 -5 -22 -3 -25 8 -7 48 27 43 37 -11 16 -33 10
  -40 -12z"
        />
        <path
          d="M5070 5309 c-33 -13 -33 -14 -20 -38 15 -29 37 -26 54 8 21 40 12 48
  -34 30z"
        />
        <path
          d="M5510 5265 c-10 -12 -9 -16 4 -21 16 -6 46 11 46 27 0 15 -37 10 -50
  -6z"
        />
        <path
          d="M5222 5244 c4 -21 22 -23 26 -1 2 10 -3 17 -13 17 -10 0 -15 -6 -13
  -16z"
        />
        <path d="M6767 5254 c-9 -10 2 -24 19 -24 8 0 14 7 14 15 0 15 -21 21 -33 9z" />
        <path
          d="M7208 5244 c-27 -14 -35 -33 -22 -47 9 -9 84 36 81 48 -5 15 -29 15
  -59 -1z"
        />
        <path
          d="M5724 5229 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5
  -16 -11z"
        />
        <path
          d="M6180 5215 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
  -15 -7 -15 -15z"
        />
        <path d="M5321 5207 c-9 -11 -8 -17 3 -26 11 -9 15 -8 20 5 9 23 -8 38 -23 21z" />
        <path
          d="M6542 5204 c4 -21 22 -23 26 -1 2 10 -3 17 -13 17 -10 0 -15 -6 -13
  -16z"
        />
        <path
          d="M6365 5181 c-9 -16 3 -31 26 -31 14 0 19 6 17 17 -4 20 -33 29 -43
  14z"
        />
        <path
          d="M6030 5165 c0 -10 7 -15 18 -13 21 4 19 22 -2 26 -10 2 -16 -3 -16
  -13z"
        />
        <path
          d="M7060 5165 c0 -10 7 -15 18 -13 21 4 19 22 -2 26 -10 2 -16 -3 -16
  -13z"
        />
        <path
          d="M5200 5113 c0 -5 10 -20 23 -35 27 -34 52 -25 41 15 -5 22 -12 27
  -35 27 -16 0 -29 -3 -29 -7z"
        />
        <path d="M6766 5111 c-7 -11 18 -33 27 -24 4 3 7 12 7 20 0 15 -26 18 -34 4z" />
        <path
          d="M5565 5100 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
  -12 6 -17 -2z"
        />
        <path d="M5836 5103 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24 19z" />
        <path
          d="M6470 5096 c0 -10 9 -16 21 -16 24 0 21 23 -4 28 -10 2 -17 -3 -17
  -12z"
        />
        <path
          d="M6960 5100 c-12 -7 -12 -12 -2 -22 9 -9 15 -9 24 0 16 16 -2 34 -22
  22z"
        />
        <path
          d="M7127 5103 c-13 -12 -7 -23 13 -23 11 0 20 7 20 15 0 15 -21 21 -33
  8z"
        />
        <path
          d="M5364 5060 c-40 -16 -37 -30 5 -30 42 0 63 16 40 31 -17 10 -18 10
  -45 -1z"
        />
        <path
          d="M5705 5060 c-8 -13 4 -32 16 -25 12 8 12 35 0 35 -6 0 -13 -4 -16
  -10z"
        />
        <path d="M6197 5064 c-9 -10 4 -34 19 -34 8 0 14 9 14 20 0 19 -19 27 -33 14z" />
        <path
          d="M7056 5055 c-4 -11 1 -15 19 -15 16 0 25 6 25 15 0 8 -9 15 -19 15
  -10 0 -21 -7 -25 -15z"
        />
        <path
          d="M6001 5036 c-12 -14 -5 -26 16 -26 15 0 18 26 4 34 -5 3 -14 0 -20
  -8z"
        />
        <path
          d="M6555 5019 c-6 -16 -9 -18 -15 -8 -7 11 -18 11 -62 1 -107 -26 -110
  -26 -153 -2 -34 19 -50 22 -88 16 -26 -4 -47 -13 -47 -19 0 -7 22 -32 49 -57
  27 -25 72 -73 100 -107 28 -35 58 -63 66 -63 8 0 22 15 31 33 34 67 64 82 64
  31 0 -22 4 -25 28 -22 19 2 28 9 30 26 2 12 8 22 13 22 5 0 9 7 9 16 0 11 -6
  15 -20 11 -33 -9 -23 17 15 37 33 18 46 36 26 36 -6 0 -11 14 -13 32 -4 38
  -23 48 -33 17z m-155 -55 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11 15
  -7z"
        />
        <path
          d="M5830 4995 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
  -15 -7 -15 -15z"
        />
        <path d="M6866 4994 c-3 -9 -3 -20 0 -25 8 -13 34 9 34 27 0 19 -26 18 -34 -2z" />
        <path
          d="M5519 4984 c-10 -13 -10 -17 2 -25 20 -13 34 -6 37 19 4 26 -19 30
  -39 6z"
        />
        <path
          d="M5876 4974 c-4 -10 -1 -14 6 -12 15 5 23 28 10 28 -5 0 -13 -7 -16
  -16z"
        />
        <path
          d="M5420 4973 c0 -5 5 -15 10 -23 7 -12 12 -12 22 -2 10 10 10 15 -2 22
  -18 12 -30 13 -30 3z"
        />
        <path
          d="M7135 4970 c-11 -18 13 -36 31 -25 9 6 16 15 17 20 3 14 -40 18 -48
  5z"
        />
        <path
          d="M6724 4949 c-3 -6 1 -18 9 -27 14 -13 18 -13 32 -2 16 13 15 15 -1
  27 -22 16 -31 16 -40 2z"
        />
        <path
          d="M6992 4948 c7 -19 38 -22 38 -4 0 10 -9 16 -21 16 -12 0 -19 -5 -17
  -12z"
        />
        <path
          d="M6100 4934 c-14 -7 -25 -19 -25 -26 0 -20 28 -20 53 0 32 25 9 45
  -28 26z"
        />
        <path
          d="M5757 4893 c-13 -12 -7 -23 13 -23 11 0 20 7 20 15 0 15 -21 21 -33
  8z"
        />
        <path
          d="M6634 4885 c-8 -20 13 -45 32 -38 8 3 14 16 14 29 0 17 -6 24 -20 24
  -11 0 -23 -7 -26 -15z"
        />
        <path
          d="M5549 4861 c-19 -15 -21 -22 -12 -32 9 -9 17 -8 37 5 28 18 35 46 12
  46 -8 0 -24 -9 -37 -19z"
        />
        <path
          d="M6864 4865 c-8 -20 2 -45 18 -45 7 0 24 9 38 20 l25 19 -28 10 c-38
  15 -47 14 -53 -4z"
        />
        <path
          d="M5924 4856 c-15 -11 -16 -17 -7 -27 9 -9 18 -8 38 5 25 17 26 36 1
  36 -7 0 -21 -6 -32 -14z"
        />
        <path
          d="M6144 4849 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5
  -16 -11z"
        />
        <path
          d="M7047 4853 c-13 -12 -7 -23 13 -23 11 0 20 7 20 15 0 15 -21 21 -33
  8z"
        />
        <path d="M6296 4833 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24 19z" />
        <path
          d="M6695 4830 c-4 -7 -3 -16 3 -22 14 -14 45 -2 40 15 -6 16 -34 21 -43
  7z"
        />
        <path
          d="M6004 4819 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5
  -16 -11z"
        />
        <path
          d="M6240 4796 c0 -7 -9 -16 -20 -19 -23 -6 -27 -37 -5 -37 9 0 30 7 46
  16 30 15 30 16 13 35 -20 22 -34 25 -34 5z"
        />
        <path
          d="M5760 4770 c0 -21 8 -24 34 -14 25 10 19 34 -9 34 -18 0 -25 -5 -25
  -20z"
        />
        <path d="M6436 4773 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24 19z" />
        <path d="M6980 4764 c0 -9 7 -14 17 -12 25 5 28 28 4 28 -12 0 -21 -6 -21 -16z" />
        <path d="M6523 4745 c-3 -9 -3 -18 0 -22 10 -10 37 6 37 22 0 20 -29 20 -37 0z" />
        <path d="M5594 4719 c-8 -14 11 -33 25 -25 6 4 11 14 11 22 0 16 -26 19 -36 3z" />
        <path
          d="M6045 4720 c-7 -12 2 -30 15 -30 5 0 14 9 20 20 9 17 8 20 -9 20 -11
  0 -23 -4 -26 -10z"
        />
        <path d="M6720 4674 c0 -9 7 -14 17 -12 25 5 28 28 4 28 -12 0 -21 -6 -21 -16z" />
        <path
          d="M6843 4683 c-7 -2 -13 -9 -13 -15 0 -12 41 -6 41 7 1 12 -11 15 -28
  8z"
        />
        <path d="M5886 4665 c-19 -15 -19 -15 2 -15 13 0 22 6 22 15 0 18 0 18 -24 0z" />
        <path
          d="M6120 4641 c0 -44 11 -58 30 -36 17 20 9 69 -12 73 -15 3 -18 -4 -18
  -37z"
        />
        <path
          d="M6368 4666 c-21 -16 -24 -36 -5 -36 22 0 51 26 43 38 -9 15 -17 15
  -38 -2z"
        />
        <path d="M6270 4656 c0 -9 7 -16 16 -16 17 0 14 22 -4 28 -7 2 -12 -3 -12 -12z" />
        <path
          d="M5685 4630 c-8 -13 3 -30 20 -30 14 0 27 24 18 34 -10 9 -31 7 -38
  -4z"
        />
        <path d="M6586 4621 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z" />
        <path
          d="M6013 4613 c-21 -7 -15 -30 9 -36 23 -6 38 -1 38 13 0 13 -32 28 -47
  23z"
        />
        <path d="M6327 4593 c-3 -3 -3 -11 0 -18 5 -14 33 -9 33 5 0 10 -26 20 -33 13z" />
        <path
          d="M6423 4583 c9 -9 19 -14 23 -11 10 10 -6 28 -24 28 -15 0 -15 -1 1
  -17z"
        />
        <path
          d="M5847 4575 c-4 -8 -2 -17 4 -20 15 -10 49 5 49 21 0 19 -46 18 -53
  -1z"
        />
        <path
          d="M6593 4548 c2 -7 12 -13 22 -13 10 0 20 6 23 13 2 7 -6 12 -23 12
  -17 0 -25 -5 -22 -12z"
        />
        <path
          d="M6480 4525 c-10 -12 -9 -16 5 -21 19 -8 40 9 31 25 -10 15 -21 14
  -36 -4z"
        />
        <path
          d="M6780 4520 c0 -23 10 -26 28 -8 18 18 15 28 -8 28 -13 0 -20 -7 -20
  -20z"
        />
        <path
          d="M6674 4505 c-8 -20 16 -49 29 -36 11 11 0 51 -14 51 -5 0 -12 -7 -15
  -15z"
        />
        <path
          d="M6117 4503 c-12 -11 -8 -18 24 -37 33 -20 59 -16 59 10 0 10 -5 14
  -14 11 -8 -3 -20 1 -26 9 -13 15 -32 18 -43 7z"
        />
        <path
          d="M6012 4483 c-18 -7 -4 -23 19 -23 12 0 19 5 17 12 -5 14 -18 18 -36
  11z"
        />
        <path
          d="M6295 4481 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
  -9z"
        />
        <path
          d="M6526 4454 c-8 -22 -6 -23 22 -13 13 5 20 13 17 19 -10 15 -32 12
  -39 -6z"
        />
        <path
          d="M6430 4355 c-10 -12 -9 -16 3 -21 9 -3 24 -1 33 4 16 9 16 10 -3 21
  -16 8 -24 7 -33 -4z"
        />
        <path
          d="M6530 4355 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
  -15 -7 -15 -15z"
        />
        <path
          d="M5877 5839 c-19 -11 -14 -29 8 -29 14 0 28 27 17 34 -4 2 -15 0 -25
  -5z"
        />
        <path
          d="M5827 3897 c-8 -21 8 -39 29 -35 27 5 25 42 -2 46 -12 2 -24 -3 -27
  -11z"
        />
        <path
          d="M5200 3874 c-11 -12 -10 -18 3 -32 16 -15 18 -15 34 0 13 14 14 20 3
  32 -7 9 -16 16 -20 16 -4 0 -13 -7 -20 -16z"
        />
        <path
          d="M6734 3736 c-9 -24 4 -48 23 -44 12 2 18 12 18 28 0 29 -32 41 -41
  16z"
        />
        <path
          d="M7083 3724 c-3 -8 1 -22 9 -30 13 -14 17 -14 31 1 9 8 14 22 11 30
  -8 20 -43 19 -51 -1z"
        />
        <path
          d="M5300 3645 c0 -22 4 -26 23 -23 14 2 22 10 22 23 0 13 -8 21 -22 23
  -19 3 -23 -1 -23 -23z"
        />
        <path
          d="M5514 3565 c-8 -20 4 -35 26 -35 23 0 32 13 24 34 -8 20 -43 21 -50
  1z"
        />
        <path
          d="M5750 3535 c0 -22 4 -26 23 -23 14 2 22 10 22 23 0 13 -8 21 -22 23
  -19 3 -23 -1 -23 -23z"
        />
        <path
          d="M6850 3515 c-16 -19 -6 -45 19 -45 23 0 35 28 20 46 -15 18 -24 18
  -39 -1z"
        />
        <path
          d="M6974 3515 c-8 -19 3 -35 26 -35 19 0 30 17 23 38 -6 17 -42 15 -49
  -3z"
        />
        <path
          d="M6603 3454 c-3 -8 2 -23 11 -32 14 -15 18 -15 31 -1 9 9 13 23 9 32
  -8 21 -43 22 -51 1z"
        />
        <path
          d="M6640 3354 c-11 -12 -10 -18 3 -32 16 -15 18 -15 34 0 13 14 14 20 3
  32 -7 9 -16 16 -20 16 -4 0 -13 -7 -20 -16z"
        />
        <path
          d="M6167 3294 c-10 -10 -9 -42 2 -48 18 -11 42 6 39 28 -3 21 -28 33
  -41 20z"
        />
        <path
          d="M6371 3270 c-19 -11 -9 -45 14 -45 13 0 21 8 23 21 3 23 -17 37 -37
  24z"
        />
        <path
          d="M2422 3308 c-5 -7 -20 -73 -31 -146 -12 -74 -31 -158 -42 -187 -27
  -71 -19 -105 25 -104 46 1 158 59 176 91 14 27 14 29 -29 72 -24 24 -41 50
  -39 58 3 7 23 19 44 27 45 16 74 38 74 58 0 7 -24 29 -54 48 -30 19 -64 48
  -76 65 -23 33 -32 36 -48 18z m70 -105 c43 -29 40 -38 -14 -48 l-38 -7 0 41
  c0 22 3 41 7 41 4 0 24 -12 45 -27z m-19 -201 c18 -20 18 -21 -5 -35 -12 -8
  -33 -18 -45 -22 -27 -7 -30 12 -12 63 12 35 27 33 62 -6z"
        />
        <path
          d="M2810 3141 c0 -5 -22 -42 -48 -82 -26 -41 -54 -89 -61 -107 -8 -18
  -27 -49 -42 -69 -50 -63 -29 -110 24 -53 25 26 35 31 60 26 16 -3 33 -6 38 -6
  5 0 9 -9 9 -21 0 -31 18 -59 35 -53 9 4 15 19 15 39 0 19 5 37 11 40 6 4 7 14
  4 24 -4 9 -7 66 -6 126 1 61 -1 118 -5 128 -6 17 -34 24 -34 8z m-2 -168 l-3
  -58 -37 -3 c-33 -3 -37 -1 -32 15 11 29 58 103 67 103 4 0 7 -26 5 -57z"
        />
        <path
          d="M3070 3085 c-6 -8 -9 -19 -5 -25 4 -6 1 -17 -5 -25 -12 -15 -22 -47
  -44 -150 -8 -33 -21 -81 -31 -106 -34 -88 -2 -106 85 -50 35 23 71 41 82 41
  10 0 27 9 37 19 19 19 19 21 -24 105 -31 59 -46 103 -51 145 -7 62 -20 75 -44
  46z m48 -202 l21 -52 -50 -30 c-57 -36 -58 -34 -34 68 24 100 28 101 63 14z"
        />
        <path
          d="M2113 2572 c-18 -11 -33 -59 -33 -106 0 -24 -11 -77 -26 -117 -42
  -122 -42 -114 12 -133 67 -23 108 -21 141 8 23 20 26 27 15 38 -10 10 -17 10
  -32 0 -23 -14 -59 -15 -78 -3 -12 7 -10 34 9 155 22 142 20 175 -8 158z"
        />
        <path
          d="M2446 2491 c-4 -5 -22 -56 -42 -113 -19 -56 -49 -125 -65 -152 -22
  -36 -29 -56 -24 -75 l7 -26 36 41 c40 44 83 62 100 41 6 -7 12 -35 14 -62 3
  -42 6 -50 23 -50 19 0 20 6 17 170 -3 184 -12 235 -42 235 -10 0 -21 -4 -24
  -9z m24 -161 l0 -60 -30 0 c-16 0 -30 5 -30 10 0 13 49 110 55 110 3 0 5 -27
  5 -60z"
        />
        <path
          d="M2697 2383 c-14 -32 -32 -90 -40 -130 -8 -39 -22 -82 -31 -94 -21
  -30 -21 -88 -1 -95 20 -8 43 12 47 39 5 33 29 27 55 -13 42 -64 63 -81 84 -70
  26 15 24 45 -6 64 -13 9 -31 29 -40 46 l-15 29 45 27 c25 14 45 33 45 43 0 9
  -15 46 -34 81 -19 36 -37 80 -41 98 -11 52 -38 42 -68 -25z m68 -93 c14 -27
  25 -51 25 -54 0 -6 -60 -26 -78 -26 -12 0 -13 10 -8 50 13 96 24 102 61 30z"
        />
        <path
          d="M2950 2318 c-11 -24 -20 -63 -20 -86 0 -24 -6 -56 -14 -70 -33 -64
  -48 -163 -27 -176 18 -11 40 6 46 35 4 16 10 29 15 29 4 0 31 -36 59 -80 47
  -74 53 -80 72 -70 16 9 19 17 14 38 -8 32 -32 72 -44 72 -4 0 -20 22 -34 49
  l-27 48 41 6 c23 3 49 13 58 22 15 14 14 18 -3 48 -11 18 -32 55 -46 82 -39
  72 -54 95 -63 95 -4 0 -17 -19 -27 -42z m71 -100 c24 -46 15 -64 -25 -54 -22
  5 -26 12 -26 40 0 42 9 68 21 60 5 -3 19 -24 30 -46z"
        />
        <path
          d="M3200 2269 c-9 -15 -7 -36 6 -87 17 -63 20 -137 12 -277 -3 -44 -1
  -50 17 -50 15 0 23 11 32 45 6 25 12 47 14 50 3 8 6 32 8 73 0 20 9 45 19 55
  29 30 102 141 102 157 0 30 -29 14 -81 -46 l-53 -62 -13 29 c-6 16 -15 53 -18
  82 -8 56 -26 68 -45 31z"
        />
      </g>
    </svg>
  );
}
