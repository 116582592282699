import * as React from "react";
import BadLarry from "../components/BadLarry";
const IndexPage = () => {
  return (
    <main className="w-full max-w-3xl text-patty mx-auto my-8 font-mono px-2 lg:px-0">
      <title>BAD LARRY BURGER CLUB</title>
      <h1 className="text-2xl text-center">
        BAD LARRY BURGER CLUB INTERNET BURGER WEBSITE
      </h1>
      <BadLarry />
      <blockquote className="italic bg-dark-bun p-4 my-4">
        "Ok. I think it needs to look like it was made in the early days of
        internet. HTML? I don't know what I'm saying. Info
        <br />
        <br />
        BAD LARRY BURGER CLUB
        <br />
        <br />
        I am not a restaurant. I am just some guy that makes cheese burgers
        sometimes. I tell instagram when and where that's gonna happen.
        <br />
        <br />
        <a className="text-ketchup" href="//instagram.com/badlarryburgerclub">
          @badlarryburgerclub
        </a>
        <br />
        <br />
        It's B. Larry. Not P. Terry. Mabey put the larry guy on there
        somewhere."
      </blockquote>
      <div className="flex flex-row items-center mt-4">
        <div className="flex flex-col">
          <div className="w-24 h-2 bg-dark-bun"></div>
          <div className="w-24 h-2 bg-pickle"></div>
          <div className="w-24 h-2 bg-cheese"></div>
          <div className="w-24 h-2 bg-patty"></div>
          <div className="w-24 h-2 bg-ketchup"></div>
          <div className="w-24 h-2 bg-dark-bun"></div>
        </div>
        <div className="ml-4 uppercase text-sm">made in burgaria</div>
      </div>
    </main>
  );
};

export default IndexPage;
